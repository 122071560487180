.contactContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: white;
    color: #232323;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px 0px 20px 0;
    /* margin: 0 10px; */
    align-items: center;
}

.contactContainer h1{
    font-family: "Merriweather", serif !important;
    font-size: 2em;
    border-bottom: 2px solid rgb(247, 243, 243);
    white-space: nowrap;
}

.contactContainerInfo{
    width: 60%;
    text-align: left;
    max-width: 600px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactContainerInfo *{
    width: 100%;
}

.contactContainerInfo h1{
    margin-top: 0;
}

.contactContainerInfo *{
    font-family: "Merriweather", serif !important;
    white-space: nowrap;
}

.contactForm{
    width: 60%;
    max-width: 800px;
    margin: 20px;
    height: 100%;
    display: flex;
    color: #232323;
    margin: 0 auto;
    background-color: #fddc11;
    border-radius: 10px;
    /* width: 100%; */
    /* display: flex; */
    flex-direction: column;
    justify-content: space-around;
    padding: 20px 0 20px 0;
    border: 2px solid #232323;
}

.contactForm div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactForm div label{
    width: 80%;
    text-align: left;
}

.contactForm form{
    
}

.contactForm input, .contactForm textarea {
    width: 80%;
    margin: 10px;
    border: none;
    border-bottom: 2px solid black;
    background-color: #fddc11;
    line-height: 2em;
    font-size: 1.2em;
    transition: 0.2s ease-in-out;
}

.contactForm input:hover, .contactForm textarea:hover {
    border-bottom: 2px solid #fd9f11;
}

.contactForm button{
    background-color: #232323;
    color: #fddc11;
    cursor: pointer;
    font-family: "Merriweather", serif !important; 
    /* border-radius: 10px; */
    width: 30%;
    font-size: 2rem;
    border: none;
    padding: 4px;
    border: 1px solid #fddc11;
    transition: 0.2s ease-in-out;
}

.contactForm form button:hover{
    background-color: #fddc11;
    color: #232323;
    border: 1px solid #232323;
}

.contactContainerInfo svg{
    /* flex: 1; */
    font-size: 1.3rem;
    padding: 5px;
    border-radius: 50%;
    background-color: #232323;
    color: #fddc11;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    aspect-ratio: 1;
    width: auto;
}

.contactContainerInfo svg:hover{
    background-color: white;
    color: #232323;
}

.contactDetail{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contactDetail a, .contactDetail h2, .contactDetail div{
    width: fit-content;
}

.contactSpacer{
    flex-grow: 1;
    margin: 10px 10px;
    height: 2px;
    border-bottom: 4px dotted #fddc11;
}

.valid{
    display: none;
  }
  
  .invalid{
    display: block;
    color: red;
    width: 80%;
  }

@media screen and (max-width: 1020px){

    .contactForm{
        max-width: 600px;
        width: 100%;
        /* margin: 20px 0px 20px 0px; */
    }

    .contactContainerInfo{
        max-width: 600px;
        width: 80%;
        text-align: left;
        /* margin: 20px 0px 20px 0px; */
    }

    .contactContainer h1{
        white-space: normal;
    }
  }

