.contactWidget{
    border-radius: 10px;
    width: 300px;
    color: #232323;
    transition: 0.2s ease;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 1001;
}

.formOpenButton{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.innerWidget{
    background-color: #fddc11;
    border: 2px solid red;
    position: fixed;
    right: 10px;
    bottom: -185px;
    border-radius: 10px;
    width: 300px;
    transition: 0.2s ease-in-out;
    padding-top: 10px;
    padding-bottom: 10px;
}

.innerWidget form h1{
    margin: 0;
    padding-bottom: 5px;
    font-weight: bolder;
    font-family: "Merriweather", serif !important;
}

.moveInner{
    bottom: 10px !important;
}

.contactWidget svg{
    font-size: 24px;
    color: #232323;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.contactWidget svg:hover{
    color: #454545;
}

.formButton{
    cursor: pointer;
    position: fixed;
    right: 10px;
    bottom: 10px;
    padding: 5px;
    border-radius: 50%;
    background-color: #232323;
}

.formButton svg{
    font-size: 24px;
    color: #fddc11;
}

.contactWidget form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #232323; */
}

.contactWidget h1{
    background-color: #fddc11;
    width: 100%;
}

.contactWidget form div{
    width: 100%;
}

.contactWidget form div input, .contactWidget form div textarea{
    width: 80%;
    border: none;
    border-bottom: 2px solid black;
    background-color: #fddc11;
    line-height: 1.2em;
    font-size: 1.2em;
    margin: 2px 0;
    transition: 0.2s ease-in-out
}

.contactWidget form div input:hover, .contactWidget form div textarea:hover{
    border-bottom: 2px solid #fd9f11;
}

.contactWidget form div input:placeholder-shown, .contactWidget form div textarea:placeholder-shown{
    font-style: italic;
}

.contactWidget form button{
    background-color: #232323;
    color: #fddc11;
    cursor: pointer;
    border-radius: 8px;
    width: 30%;
    font-size: 1rem;
    border: none;
    padding: 2px;
    border: 1px solid #fddc11;
    transition: 0.2s ease-in-out;
}

.contactWidget form button:hover{
    background-color: #fddc11;
    color: #232323;
    border: 1px solid #232323;
}

.cornerButton{
    position: fixed;
    display: none;
    bottom: 20px;
    right: 20px;
    font-size: 32px;
    color: #232323;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    background-color: #fddc11;
    border-radius: 50%;
    z-index: 1000;
    padding: 6px;
    width: 36px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
}

.cornerButton svg{
    aspect-ratio: 1;
}

.cornerButton svg path{
    aspect-ratio: 1;
}

@media screen and (max-width: 1020px){
    .innerWidget{
      bottom: -300px;
    }


    .cornerButton{
        display: block;
    }
  }