.testimonial{
    /* max-width: 500px; */
    background-color: #fddc11;
    margin: 10px;
    padding: 10px;
    border: 1px solid #232323;
    border-radius: 10px;
    min-height: 300px;
}

.testimonial *{
    font-family: "Merriweather", serif !important;
}

.testimonial h2{
    font-size: 1.5em;
}

.testimonial p{
    line-height: 2em;
    font-size: 1.1em;
    /* padding: 10px; */
}

.react-multi-carousel-list{
    padding-bottom: 10px;
}

.testimonialIntroduction{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.testimonialIntroduction p{
    font-size: 1.2em;
    font-weight: bold;
    line-height: 2em;
    width: 60%;
    margin: 10px 0px;
}

.testimonialIntroduction h1{
    font-size: 4em; 
    border-bottom: 5px solid rgb(253, 220, 17); 
    font-weight: bolder; 
    font-style: italic;
    margin: 0;
    max-width: 90%;
}

.testimonialIntroduction h2{
    font-size: 2.5em; 
    font-weight: bolder; 
    font-style: italic;
    margin: 0;
    width: 60%;
    margin: 20px 0 20px 0;
}

.testimonialIntroduction svg{
    /* flex: 1; */
    font-size: 3rem;
    padding: 3px;
    border-radius: 50%;
    background-color: white;
    color: #fddc11;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    aspect-ratio: 1;
    margin-bottom: 20px;
}

.testimonialIntroduction svg:hover{
    background-color: #fddc11;
    color: white;
}