.homeSection{
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: #fddc11;
    padding-top: 20px;
}

.homeSection iframe{
    border: 1px solid #232323 !important;
}

.handleFlex{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.descHero{
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
    color: white;
    font-size: 2rem;
    height: 300px;
}

.descHero div{
    z-index: 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
    color: white;
    font-size: 2rem
}

vl{
    border-left: 3px solid #fddc11;
    height: 100%;
    z-index: 9999;
  }

.descHero video {
    width: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 0;
    height: 300px;
}

.videoButton{
    font-weight: bold;
    font-style: italic;
    font-size: 1.5rem;
    color: #232323;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    padding: 10px;
    background-image: linear-gradient(to left, #232323  50%, #fddc11 50%);
    background-size: 201% 100%;
    border: 2px solid #232323;
}

.videoButton:hover{
    background-position: 100% 0%;
    color: #fddc11;
}   

.handleSection{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
    text-align: center;
    background-color: white;
    color: #232323;
    padding-top: 20px;
}

.handleSection div{
    flex: 1 1 0px;
}

.homeText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homeText h1{
    font-style: italic;
    text-align: center;
}

.infoHalf{
    background-color: #232323;
    color: #fddc11;
    flex: 1 1 auto;
    height: 100%;
    padding: 5px;
    width: 100%;
}

.rodentPic{
    width: 95%;
    height: auto;
    border-radius: 10px;
    border: 3px solid #232323;
    aspect-ratio: 1.5;
}

.handleSection button{  
    width: 200px;
    background-image: linear-gradient(to left, #232323  50%, #fddc11 50%);
    color: #232323;
    border-radius: 15px;
    border: 2px solid black;
    font-style: italic;
    font-size: 20px;
    cursor: pointer;
    background-size: 200% 100%;
    transition: 0.2s ease-in-out;
    padding: 5px;
    margin-bottom: 10px;
}

.handleSection button:hover{
    background-position: 100% 0%;
    color: #fddc11;
}

.directionsButton{
    width: 250px;
    background-image: linear-gradient(to left, #fddc11  50%, #232323 50%);
    color: #fddc11;
    border-radius: 15px;
    border: 1px solid black;
    font-style: italic;
    font-size: 20px;
    cursor: pointer;
    background-size: 200% 100%;
    transition: 0.2s ease-in-out;
    padding: 5px;
    margin-bottom: 10px;
}

.directionsButton:hover{
    background-position: -100% 0%;
    color: #232323;
}

.handleSection p {
    width: 75%;
    margin: 0 auto;
    margin: 0px auto 10px;
}

.homeSection svg{
    color: black;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.homeSection svg:hover{
    color: #424242;
}

@media screen and (max-width: 1020px) {
    .handleSection{
        flex-direction: column;
    }

    .homeSection{
        flex-direction: column;
    }

    .locationSection{
        margin-top: 10px;
    }

    .descHero div{
        flex-direction: column;
    }

    vl{
        border-left: none;
        border-top: 2px solid #fddc11;
        width: 80%;
        margin: 10px;
    }
}

@media screen and (max-width: 720px) {

    .navbar-logo{
        flex: 1;
    }
    .navbar-logo img{
        width: 100%;
    }
    #navButton{
        flex: 1;
    }
}

