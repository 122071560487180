.serviceImg{
    position: relative;
    text-align: center;
    text-decoration: none;
}

.centered {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fddc11;
    opacity: 100%;
    transition: 0.2s ease-in-out;
}

.servicePic{
    width: auto;
    height: 300px;
    border-radius: 10px;
    border: 3px solid #232323;
    aspect-ratio: 1.5;
    transition: 0.2s ease-in-out;
    filter: brightness(80%);
    max-width:95%;
}

.serviceSection{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
    text-align: center;
    background-color: white;
    color: #232323;
    padding-top: 20px;
}

.serviceSection div{
    margin: 0 10px;
}


#residentialLink:hover .servicePic{
    filter: brightness(60%);
}

@media screen and (max-width: 1020px) {
    .serviceSection{
        flex-direction: column;
        padding: 0;
        width: 100vw;
    }

    .serviceSection div{
        padding-top: 10px;
    }
}