.navbar {
    background: #fddc11;
    min-height: 140px;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 0px;
    z-index: 999;
    height: auto;
    transition: 0.2s ease-in-out;
    color: #232323;
  }

  .contactBar{
    display: flex;
    justify-content: flex-end;
    background-color: #232323;
    color: #fddc11;
    box-shadow: inset 0 -8px 8px -8px #fddc11;
    width: 100%;
    z-index: 100;
    padding: 2px 0px 2px 0px;
    transition: 0.2s ease-in-out;

  }

  .contactBarShrink{
    color: #232323;
    background-color: #fddc11;
    box-shadow: inset 0 -8px 8px -8px #232323;
  }

  .contactBar div{
    margin-right: 25px;
  }

  .contactBar svg{
    margin-right: 5px;
  }

  .navShrink{
    background: #232323;
    min-height: 120px;
    color: #fddc11;
    max-height: 160px;
  }
  
  .navbar-container {
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    width: 100%;
  }

  .navbar-logo {
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  .navbar-logo img{
    transition: 0.2s ease-in-out;
  }

  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    justify-content: space-evenly;
    padding-inline-start: 0px;
    flex: 2 1 auto;
    max-width: 1200px;
  }

  #navButton{
    display: flex;
    justify-content: center;
    width: 300px;
    transition: 0.2s ease-in-out;
  }

  #navButton button{
    padding: 15px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #navButton button:hover{
    transform: scale(1.2);
  }

  @media screen and (max-width: 654px){
    .nav-menu{
      top: 96px !important;
    }
  }

  @media screen and (max-width: 498px){
    /* .nav-menu{
      top: 128px !important;
    } */
  }

  .nav-item {
    height: 80px;
    width: 19%;
  }

  .gradientEffect{
    color: #232323;
    border-bottom: #232323 2px solid;
    background-image: linear-gradient(to top, #232323  50%, #fddc11 50%);
  }
  
  .nav-links {
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    justify-content: center;
    transition: 0.2s ease-in-out;
    background-size: 100% 200%;
    font-weight: bold;
  }

  .nav-links svg{
    font-size: 14px;
    margin-top: 4px;
    margin-left: 8px;
  }

  .serviceList{
    transform: translate(0px, -30px);
    position: absolute;
    opacity: 0;
    transition: transform 0.2s ease-in-out;
    width: 100%;
    border: 1px solid #232323;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 1000;
  }

  #serviceButton:hover .serviceList{
    opacity: 1;
    pointer-events: all;
    visibility: visible;
    transform: translate(0px, 0px);
    display: list-item;
    position: static;
    top: auto;
  }

  .gradientEffectShrink{
    background-image: linear-gradient(to top, #fddc11  50%, #232323 50%);
    color: #fddc11;
    border-bottom: #fddc11 2px solid;
  }

  .nav-links:hover{
    background-position: 0 100%;
  }

  .gradientEffect:hover{
    color: #fddc11;
    background-color: #232323;
  }

  .gradientEffectShrink:hover{
    color: #232323;
    background-color: #fddc11;
  }
     
  .nav-links-mobile {
    display: none;
  }

  @media screen and (max-width: 1020px) {

    /* #serviceButton{
      pointer-events: none;
    } */

    #serviceButton:hover .serviceList{
      display: none;
    }

    /* #serviceButton:focus .serviceList{
      opacity: 1;
      pointer-events: all;
      visibility: visible;
      transform: translate(0px, 0px);
      display: list-item;
      position: static;
      top: auto;
    } */

    .serviceList{
      display: none;
    }

    .NavbarItems {
      position: relative;
    }

    .nav-menu {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 80px;
        left: -120%;
        opacity: 1;
        transition: all 0.5s ease;
        margin: 0;
      }
    
      .nav-menu.active {
        flex: 1;
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        width: 100%;
        height: auto;
        border-top: 2px solid #232323;
      }

      .nav-item{
        width: 100%;
        height: auto;
      }

      .serviceList{
        position: absolute !important;
      }
    
      .nav-links {
        text-align: center;
        padding: 5px;
        width: 100%;
        display: table;
      }
    
      .nav-links:hover {
        background-color: #fff;
        border-radius: 0;
      }
    
      .navbar-logo {
        color: #fff;
        justify-self: start;
        cursor: pointer;
        text-decoration: none;
        font-size: 2rem;
        display: flex;
      }

      .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
      }
    
      .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
      }

      .menu-icon {
        flex: 1;
        display: flex !important;
        justify-content: center;
        /* transform: translate(-100%, 60%); */
        font-size: 1.8rem;
        cursor: pointer;
      }
}

.menu-icon {
    flex: 1;
    display: none;
  }