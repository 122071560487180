.sousaFooter{
    height: 3rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: skyblue;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
}

.sousaFooter *{
    text-align: center;
}

.policyLinks{
    display: flex;
    justify-content: space-evenly;
}