.serviceInfo{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px 0;
}

.serviceText{
    width: 50%;
    margin: 0 71px;
    text-align: center;
}

.serviceText h1{
    color: #232323;
    text-align: center;
    font-family: "Merriweather", serif !important;
    padding-bottom: 10px;
    margin: 0px;
    font-weight: normal;
}

.serviceText p{
    line-height: 1.5em;
}

.serviceRight{
    display: flex;
    justify-content: center;
}

.serviceRight img{
    width: 80%;
    max-width: 960px;
    margin: 0 auto;
    border-radius: 10px;
    border: 3px solid #232323;
}

.serviceContact{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    background-color: #fddc11;
    color: #232323;
    align-items: center;
}

.serviceContact h1{
    font-family: "Merriweather", serif !important;
    font-size: 3em;
    border-bottom: 2px solid rgb(247, 243, 243);
}

.serviceContactInfo{
    width: 50%;
}

.serviceForm{
    width: 50%;
    margin-bottom: 10px;
}

.serviceForm div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.serviceForm div label{
    width: 80%;
    text-align: left;
}

.serviceForm form{
    color: #232323;
    margin: 0 auto;
    background-color: #fddc11;
    border-radius: 10px;
    width: 90%;
}

.serviceForm input, .serviceForm textarea {
    width: 80%;
    margin: 10px;
    border: none;
    border-bottom: 2px solid black;
    background-color: #fddc11;
    line-height: 2em;
    font-size: 1.2em;
    transition: 0.2s ease-in-out;
}

.serviceForm input:hover, .serviceForm textarea:hover {
    border-bottom: 2px solid #fd9f11;
}

.serviceForm form button{
    background-color: #232323;
    color: #fddc11;
    cursor: pointer;
    font-family: "Merriweather", serif !important; 
    /* border-radius: 10px; */
    width: 30%;
    font-size: 2rem;
    border: none;
    padding: 4px;
    border: 1px solid #fddc11;
    transition: 0.2s ease-in-out;
}

.serviceForm form button:hover{
    background-color: #fddc11;
    color: #232323;
    border: 1px solid #232323;
}

.valid{
    display: none;
  }
  
  .invalid{
    display: block;
    color: red;
    width: 80%;
  }


@media screen and (max-width: 1020px) {
    .serviceInfo{
        flex-direction: column-reverse;
    }   

    .serviceText{
        width: 80%;
    }

    .serviceContactInfo{
        width: 100%;
    }
    
    .serviceForm{
        width: 100%;
    }
}