.footer{
    position: absolute;
    bottom: 3rem;
    width: 100%;
    height: 12rem;  
    background-color: #232323;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer svg{
    color: yellow;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.footer svg:hover{
    color: white;
}

.socialIcons{
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #fddc11;
    margin-bottom: 5px;
}

.socialIcons div{
    flex: 1 1 0;
    text-align: center;
}

.socialIcons a{
    font-size: 1rem;
    color: #fddc11;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    flex: 1;
    text-align: center;
    min-width: 126.67px;
}

.socialIcons a:hover{
    color: white;
}


.socialIcons svg{
    /* flex: 1; */
    font-size: 3rem;
    padding: 3px;
    border-radius: 50%;
    background-color: #fddc11;
    color: #232323;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    aspect-ratio: 1;
}

.socialIcons svg:hover{
    background-color: #232323;
    color: #fddc11;
}

.footerLinks{
    margin: 10px 0px;
    display: flex;
    flex-direction: row;
    color: #fddc11;
    cursor: pointer;
}

.footerLinks vl{
    border-width: 2px;
    height: auto;
}

.footerLinks a{
    color: #fddc11;
    margin: 0px 10px;
    text-decoration: none;
    transition: 0.2s ease-in-out;
}

.footerLinks a:hover{
    color: white;
}

.availabilityDetail{  
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    color: #fddc11;
}

.availabilityDetail a, .availabilityDetail h2, .availabilityDetail div{
    width: fit-content;
}

.spacer{
    flex-grow: 1;
    margin: 10px 10px;
    height: 2px;
    border-bottom: 2px dotted white;
}

.largeRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.availabilitySection{
    
}

#realAvailability{
    display: block;
}

@media screen and (max-width: 1020px){
    .footerLinks{
        display: none;
    }

    .availabilitySection{
        display: none;
    }
  }


